@import url('https://fonts.googleapis.com/css2?family=Rye&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ewert&family=Sancreek&display=swap');
.animate-charcter
{
  background-image: linear-gradient(
    -225deg,
    #b72525 0%,
    #e87902 15%,
    #667503 30%,
    #04a617 45%,
    #667503 60%,
    #e87902 75%,
    #b72525 100%
  ); 

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: rgb(245, 210, 140);
  background-clip: text;
  -webkit-background-clip: text;


  display: inline-block;
  font-size: 50px;
  font-family: 'Rye', serif;
}

@keyframes textclip {
    to {
      background-position: 200% center;
    }
  }